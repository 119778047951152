import React from 'react';
import Immutable from 'immutable';
import HomePage from 'components/HomePage/HomePage';
import PropTypes from 'prop-types';
import track from 'react-tracking';

import { fetchFeaturedItems } from 'modules/featuredContentItems';

const Page = ({ carouselItems, featuredItems }) => (
  <HomePage carouselItems={Immutable.fromJS(carouselItems)} featuredItems={Immutable.fromJS(featuredItems)} />
);

export async function getServerSideProps() {
  const { featured, carousel } = await fetchFeaturedItems();
  return {
    props: {
      layout: {
        className: 'home-page',
        pageMode: 'home',
        headerHeight: 0,
      },
      trackingOptions: {
        page_name: 'Home',
        page_entity: 'home',
        page_entity_name: 'home',
      },
      carouselItems: carousel,
      featuredItems: featured,
    },
  };
}

Page.propTypes = {
  carouselItems: PropTypes.instanceOf(Array),
  featuredItems: PropTypes.instanceOf(Array),
};

export default track(() => ({ page: 'Home' }))(Page);
