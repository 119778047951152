import React from 'react';
import PropTypes from 'prop-types';

import { ContentRow, ContentBlock } from 'components/UI';

const Introduction = ({ center, children, className }) => (
  <ContentRow center={center} className={className}>
    <ContentBlock>
      <header>
        <h3 className="center">
          <strong>Plan, Book, and Travel With&nbsp;Confidence</strong>
        </h3>
      </header>
      <p>
        We got into this business because we love everything about travel—from diving into the nitty gritty details of
        planning a trip to diving into the ocean from an overwater bungalow.
      </p>
      <p>
        We're here to make every part of your vacation feel like a vacation—from picking the perfect hotel to booking
        flights to arranging once in a lifetime experiences.
      </p>
      {children}
    </ContentBlock>
  </ContentRow>
);

Introduction.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Introduction;
