import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { withRouter } from 'next/router';
import { Popup } from 'components/Mapping';

import { requestPlace, setSelectedPoint, setHoveredPoint, clearSelectedPoint, clearHoveredPoint } from 'modules/places';

import jsonData from 'constants/destinations.json';

import Map from 'components/Mapping/Map';
import ErrorBoundary from 'components/ErrorBoundary';

export class WorldMap extends React.PureComponent {
  state = {
    destinations: Immutable.List(),
  };

  componentDidMount() {
    window.setTimeout(() => {
      this.setState({
        immutableDestinations: Immutable.fromJS(jsonData).toList(),
        destinations: Immutable.fromJS(jsonData).toList(),
      });
    }, 250);
  }

  onMapPointClick(point) {
    const accessCode = point.iata_code || point.get('iata_code');
    this.props.history.push({
      pathname: `/destinations/list/DE-${accessCode}`,
    });
  }

  mapMouseMove() {
    const hoveredPoint = this.props.hoveredPoint.get('longitude');
    if (hoveredPoint) {
      this.props.clearHoveredPoint();
    }
  }

  pointMouseLeave() {
    this.props.clearHoveredPoint();
  }

  pointMouseEnter(point) {
    this.props.setHoveredPoint(Immutable.fromJS(point));
  }

  render() {
    const { destinations, immutableDestinations } = this.state;
    const { hoveredPoint } = this.props;
    return (
      <ErrorBoundary silent>
        <div className="home-page-map">
          <Map
            zoom={[1.2]}
            immutablePoints={immutableDestinations}
            points={destinations}
            hoveredPoint={hoveredPoint}
            pointMouseEnter={point => this.pointMouseEnter(point)}
            pointMouseLeave={() => this.pointMouseLeave()}
            markerClassName="lark-marker--home-page"
            pointClick={point => this.onMapPointClick(point)}
            Popup={Popup}
            static
          />
        </div>
      </ErrorBoundary>
    );
  }
}

WorldMap.defaultProps = {};

WorldMap.propTypes = {};

const mapStateToProps = (state, props) => ({
  place: state.place.getIn(['items', props.accessCode]) || null,
  selectedPoint: state.place.get('selectedPoint'),
  hoveredPoint: state.place.get('hoveredPoint'),
  placeLoading: state.place.get('loading'),
  placeLoaded: state.place.get('loaded'),
});

const mapDispatchToProps = {
  requestPlace,
  setHoveredPoint,
  setSelectedPoint,
  clearHoveredPoint,
  clearSelectedPoint,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorldMap));
