import styled from 'styled-components';
import { colors, variables, toRgba } from 'styles';

export const HeroCarousel = styled.div`
  min-height: 600px;
  height: 85vh;
  max-height: calc(100vh - 100px);

  .hero-heading {
    height: 120px;
  }
`;
