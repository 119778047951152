import React from 'react';
import PropTypes from 'prop-types';

import { ContentRow, ContentBlock } from 'components/UI';

const Service = ({ center, alternate, className }) => {
  const imageUrl =
    'https://d1m2xmyf58uf17.cloudfront.net/property_images/0281427/14713/medium/sph_pool2-e1503044521930.jpg?1555082503';

  return (
    <ContentRow center={center} alternate={alternate} className={className}>
      <div>
        <img src={imageUrl} alt="Pretty" />
      </div>
      <ContentBlock>
        <header>
          <h3>
            <strong>VIP service, everywhere&nbsp;you&nbsp;go</strong>
            <div className="sub">Getting it right is our job, not yours</div>
          </h3>
        </header>
        <p>
          Our team of expert advisors and 24/7 support ensure total peace of mind and high-touch service on everything
          from spa reservations to safaris.
        </p>
        <p>
          We're here to make every part of your vacation feel like a vacation—from picking the perfect hotel to booking
          flights to arranging welcome cocktails by the pool.
        </p>
      </ContentBlock>
    </ContentRow>
  );
};

Service.propTypes = {
  alternate: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
};

export default Service;
