import styled from 'styled-components';
import { colors, variables, toRgba } from 'styles';

export const Accolades = styled.div`
  border-bottom: 1px solid ${colors.warmGray};
  max-width: 100%;
  .site-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    & > * {
      opacity: 0.5;
    }
    & svg {
      padding: 15px;
      flex: 1;
      height: 80px;
    }
  }

  .iata-agency {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    & > * {
      &:last-child {
        height: 100%;
        padding: 10px;
        border-left: 1px solid ${colors.darkGray};
        // margin-left: 10px;
      }
    }
  }
`;
