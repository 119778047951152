import React from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';

import { Loader } from 'components/UI';

import * as Styled from './Deals.styled';

const DealsWidget = dynamic(() => import('components/Deals/DealsWidget'), { loading: () => <Loader />, ssr: false });

const Deals = () => (
  <Styled.DealsSection>
    <div className="content-container">
      <div className="section-heading">
        <h2>Trending Getaways</h2>
        <p>
          When our travelers find exceptional deals, we share them with you. Round trip flights and hotel for 2 - what
          you see is what you pay.
        </p>
      </div>

      <div className="invisible-mobile">
        <DealsWidget className="section-body" />
        <br />
        <p className="center">Those are just a few of hundreds of amazing options, for more - check out these links</p>
      </div>

      <div className="deals-link-buttons">
        <div>
          <Link href="/deals?long_weekend=true">
            <a className="button button-subtle">Long Weekends</a>
          </Link>
        </div>
        <div>
          <Link href="/deals?alliance=oneworld">
            <a className="button button-subtle">With American Flights</a>
          </Link>
        </div>
        <div>
          <Link href="/deals">
            <a className="button button-subtle">Browse All</a>
          </Link>
        </div>
        <div>
          <Link href="/deals?alliance=skyteam">
            <a className="button button-subtle">With Delta Flights</a>
          </Link>
        </div>
        <div>
          <Link href="/deals?alliance=star">
            <a className="button button-subtle">With United Flights</a>
          </Link>
        </div>
      </div>
    </div>
  </Styled.DealsSection>
);

export default Deals;
