import styled from 'styled-components';
import { colors, variables, toRgba } from 'styles';

export const PromotionalContent = styled.div`
  & > * {
    border-top: 1px solid ${colors.warmGray};
    padding: 2rem 0;
    &:nth-child(odd) {
      background: ${colors.white};
    }
  }
  .content-container {
    margin: 0 auto;
  }
`;

export const HomePage = styled.div`
  position: relative;
  .site-wrapper {
    padding-top: 0 !important;
  }

  .alert-list-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    top: 60px;
    width: 100%;
    margin: 10px auto;
  }

  section {
    border-bottom: 1px solid ${colors.coolGray};
    padding-bottom: 5rem;
    background: ${colors.white};
    &.alternate {
      background: ${colors.paleGray};
    }
  }

  h1 {
    font-family: ${variables.fontFamily.sansSerif};
    font-weight: 400;
    margin-bottom: 0;
    @media (max-width: ${variables.screen.xs}) {
      font-size: 2.3rem;
      font-weight: 500;
    }
  }

  .home-page-map {
    height: 60vh;
    @media (max-width: ${variables.screen.xs}) {
      display: none;
    }
  }

  .site-body {
    background: ${colors.paleGray};
    .site-container {
      padding: 0;
    }
  }

  @media (max-width: 1140px) {
    .pane {
      padding: 0 25px;
    }
  }
`;

export default HomePage;
