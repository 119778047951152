import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import track from 'react-tracking';

import { truncate } from 'components/Helpers/String';
import { ContentTile } from 'components/UI';

import { HotelLink, CollectionLink, ArticleLink } from 'components/Links';

import * as Styled from './FeaturedItems.styled';

const formatSummary = item => {
  const contentText = item.get('summary') || item.getIn(['editorial', 'it_is']) || item.get('description');
  return truncate(contentText, 84);
};

const ItemContentTile = ({ item, position }) => {
  const itemHeading = (
    <div>
      <em>{item.get('category')}</em>
      {item.get('heading') ? `: ${item.get('heading')}` : null}
    </div>
  );

  return (
    <ContentTile
      imageUrl={item.getIn(['images', 'tile']) || item.get('image_url')}
      summary={formatSummary(item)}
      name={item.get('title') || item.get('name')}
      contentType={item.get('content_type')}
      itemHeading={itemHeading}
      position={position}
    />
  );
};

ItemContentTile.propTypes = {
  item: PropTypes.instanceOf(Immutable.Map),
  position: PropTypes.number,
};

const LinkedContentTile = ({ item, position }) => {
  const links = {
    blog_article: (
      <ArticleLink article={item}>
        <ItemContentTile item={item} position={position} />
      </ArticleLink>
    ),
    hotel: (
      <HotelLink hotel={item}>
        <ItemContentTile item={item} position={position} />
      </HotelLink>
    ),
    hotel_collection: (
      <CollectionLink collection={item}>
        <ItemContentTile item={item} position={position} />
      </CollectionLink>
    ),
  };
  return links[item.get('content_type')];
};

const FeaturedItems = ({ items, className }) => (
  <Styled.FeaturedItems className={className}>
    {items.entrySeq().map(([key, item], index) => (
      <LinkedContentTile key={key} item={item} position={index} />
    ))}
  </Styled.FeaturedItems>
);

FeaturedItems.propTypes = {
  items: PropTypes.instanceOf(Immutable.List),
  className: PropTypes.string,
};

export default track(() => ({ components: ['FeaturedItems'] }))(FeaturedItems);
