import React from 'react';
import PropTypes from 'prop-types';

import { ContentRow, ContentBlock } from 'components/UI';

const Flights = ({ center, alternate, className }) => {
  const imageUrl =
    'https://www.visitmysmokies.com/wp-content/uploads/2017/12/The-wing-of-an-airplane-and-a-beautiful-sky.jpg';

  return (
    <ContentRow center={center} alternate={alternate} className={className}>
      <div>
        <img src={imageUrl} alt="Pretty" />
      </div>
      <ContentBlock>
        <header>
          <h3>
            <strong>Exclusive deals with the major&nbsp;airlines</strong>
            <div className="sub">We know people, you go places</div>
          </h3>
        </header>
        <p>
          We can save you up to 60% on Business and First Class fares worldwide thanks to our negotiated contracts with
          every major airline (Delta, United, American, Emirates, and all the rest).
        </p>
        <p>
          Plus, we know that navigating all of the new guidelines can be a little tricky. Don't worry, our Flight
          Experts have your back and will get you from A to Z regardless of route changes, missed connections, or a
          blizzard in Barbados.
        </p>
      </ContentBlock>
    </ContentRow>
  );
};

Flights.propTypes = {
  alternate: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
};

export default Flights;
