import styled from 'styled-components';
import { colors, variables, toRgba } from 'styles';

export const FeaturedItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > a {
    display: block;
    width: calc(33% - 20px);
    height: 100%;
    @media (max-width: ${variables.screen.xs}) {
      width: 100%;
    }
  }
`;
