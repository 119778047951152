import React from 'react';
import PropTypes from 'prop-types';
import Immutable, { isList } from 'immutable';
import track from 'react-tracking';
import useIsMobile from 'lib/UserAgent';

// import { openModal } from 'modules/modals';
import { useModal } from 'hooks';

import { ItemCarousel } from 'components/UI';
import { TripSearch } from 'components/Controls';

import * as Styled from './HeroCarousel.styled';

const HeroCarousel = ({ isLoaded, items, heading, subheading }) => {
  const isMobile = useIsMobile();
  const [openModal, closeModal] = useModal();
  const onSearchClick = () =>
    openModal('COMPONENT', { component: <TripSearch onClose={closeModal} context="home hero" /> });

  return (
    <Styled.HeroCarousel>
      {isLoaded && (
        <ItemCarousel items={items}>
          <div className="hero-heading">
            <h1>{heading}</h1>
            <h3>{subheading}</h3>
            {isMobile.phone && (
              <button type="button" className="button button-cta" onClick={() => onSearchClick()}>
                Search
              </button>
            )}
          </div>
          <TripSearch context="home hero" className="search-control-bar" submitText="SEARCH" />
        </ItemCarousel>
      )}
    </Styled.HeroCarousel>
  );
};

HeroCarousel.defaultProps = {
  items: Immutable.List(),
  heading: "Booking is just the beginning.",
  subheading: "Skylark combines real time technology with human expertise."
};

HeroCarousel.propTypes = {
  isLoaded: PropTypes.bool,
  items: PropTypes.instanceOf(Immutable.List),
};

export default track(() => ({ components: ['HeroCarousel'] }))(HeroCarousel);
