import React from 'react';
import dynamic from 'next/dynamic';

import { Loader } from 'components/UI';

import * as Styled from './Accolades.styled';

const SvgVirtuosoMember = dynamic(() => import('components/svg/SvgVirtuosoMember'), {
  loading: () => <Loader />,
  ssr: false,
});
const SvgTravelLeisure = dynamic(() => import('components/svg/SvgTravelLeisure'), {
  loading: () => <Loader />,
  ssr: false,
});
const SvgIataLogo = dynamic(() => import('components/svg/SvgIataLogo'), { loading: () => <Loader />, ssr: false });

const Accolades = ({ ...props }) => (
  <Styled.Accolades {...props}>
    <div className="site-container">
      <div className="site-container">
        <SvgVirtuosoMember height={50} />
        <div>
          <div className="iata-agency">
            <SvgIataLogo height={50} />
            <div>
              Accredited
              <br />
              Agency
            </div>
          </div>
        </div>
        <SvgTravelLeisure height={50} />
      </div>
    </div>
  </Styled.Accolades>
);

export default Accolades;
