import React, { useState, useEffect } from 'react';

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import useIsMobile from 'lib/UserAgent';
import LazyLoad from 'react-lazyload';

import { useAuth } from 'hooks';

import { Loader, AlertList } from 'components/UI';
import * as PromotionalContent from 'components/PromotionalContent';
import ProspectSignUp from 'components/ProspectSignUp';
import { WorldMap } from 'components/HomePage/WorldMap';
import Deals from './Deals/Deals';
import Accolades from './Accolades/Accolades';
import HeroCarousel from './HeroCarousel/HeroCarousel';
import FeaturedItems from './FeaturedItems/FeaturedItems';

import * as Styled from './HomePage.styled';

// const WorldMap = dynamic(() => import('components/HomePage/WorldMap'), { loading: () => <Loader />, ssr: false });

const SectionHeading = ({ title, children }) => (
  <div className="section-heading">
    <h2>{title}</h2>
    <p>{children}</p>
  </div>
);

SectionHeading.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const HomePage = ({ carouselItems, featuredItems }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const isMobile = useIsMobile();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Styled.HomePage>
      <div className="alert-list-container">
        <div className="site-container">
          <AlertList closeButton scrollTo />
        </div>
      </div>
      {/* Waiting for isLoaded forces client render, keeping mobile from breaking */}
      <HeroCarousel isLoaded={isLoaded} items={Immutable.fromJS(carouselItems)} />

      <div className="invisible-mobile">
        <Accolades className="invisible-mobile" />
        {/* {!isMobile.phone && <Deals />} */}
        <div className="content-container">
          <SectionHeading title="Explore our world">
            Informative articles, our favorite hotels, and collections to inspire. Just a few things on the top of our mind.
          </SectionHeading>
          {!isMobile.phone && <FeaturedItems className="section-body featured-content-items" items={Immutable.fromJS(featuredItems)} />}
        </div>
      </div>

      <Styled.PromotionalContent>
        <div>
          <PromotionalContent.Introduction center className="content-container">
            <p>Below are just a few of the many reasons thousands of people prefer traveling with Skylark.</p>
          </PromotionalContent.Introduction>
        </div>
        <div>
          <PromotionalContent.Hotels center className="content-container" />
        </div>
        <div>
          <PromotionalContent.Flights center alternate className="content-container" />
        </div>
        <div>
          <PromotionalContent.Service center className="content-container" />
        </div>
      </Styled.PromotionalContent>

      {!isMobile.phone && (
        <LazyLoad height={400} offset={250} placeholder={<Loader />}>
          <WorldMap />
        </LazyLoad>
      )}

      {!isLoggedIn && isLoaded && <ProspectSignUp />}
    </Styled.HomePage>
  );
};

HomePage.propTypes = {
  carouselItems: PropTypes.any.isRequired,
  featuredItems: PropTypes.any.isRequired,
};

export default HomePage;
